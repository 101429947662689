import React from 'react';
import SubscriptionUpdates from '../../components/activity/subscription-updates';
import SEO from '../../components/common/seo';
import EnvironmentsSummaryCard from '../../components/home/environments-summary-card';

export default function Environments() {
  return (
    <>
      <SEO title="NavAbility Robots" />
      {/* <Paywall> */}
      <EnvironmentsSummaryCard/>
      <SubscriptionUpdates />
      {/* </Paywall> */}
    </>
  );
}
