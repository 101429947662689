import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Box, Button,
  Card, CardActions, CardContent, CardHeader, IconButton, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography
} from '@mui/material';
import React from 'react';
import {
  DarkNegativeCreateIcon, DocumentIcon
} from '../icons/icons';

const exampleData = [
  {
    name: 'Warehouse Map PoC',
    lastActive: '2 hours ago',
    robots: 'WareMouserA, WareMouserB',
    sessions: "WareMouser1, WareMouser2",
    size: "500 variables, 23 landmarks, 500Mb sensor data",
  },
  {
    name: 'Phoenix',
    lastActive: '2 hours ago',
    robots: 'SelfDriver',
    sessions: "Phoenix1, PhoenixDowntown",
    size: "500 variables, 23 landmarks, 500Mb sensor data",
  },
  {
    name: 'Boston Harbor',
    lastActive: '3 hours ago',
    robots: 'Seagrant REx',
    sessions: "Harbor1, Harbor2, HarborFix",
    size: "500 variables, 23 landmarks, 500Mb sensor data",
  },
];

export default function EnvironmentsSummaryCard() {
  // const globalState = useApolloClient();

  return (
    <Box>
      <Card style={{padding: "10px"}}>
        <CardHeader title={'Environments'}></CardHeader>
        <CardContent>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Tooltip title="ID">
                      <Typography></Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Environment">
                      <Typography style={{ fontWeight: 600 }}>Environment</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Robots</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Sessions</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Size</Typography>
                    </Tooltip>
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title="Last active">
                      <Typography>Last active</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Navigate">
                      <Typography>Actions</Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exampleData.map((robot, i) => (
                  <TableRow key={i.toString()}>
                    <TableCell align="center">
                      <DocumentIcon fontSize="large" />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={robot.name}>
                        <Typography style={{ fontWeight: 600 }}>{robot.name}</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Typography><a href="/">{robot.robots}</a></Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography><a href="/">{robot.sessions}</a></Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography><a href="/">{robot.size}</a></Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography>{robot.lastActive}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton edge="end" size="large">
                        <KeyboardArrowRight fontSize="large" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="secondary"
            component="span"
            startIcon={<DarkNegativeCreateIcon fontSize="large" />}
          >
            Go to robots
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
